<template>
    <div>
        <Header
            :description="attribute.description"
            :help="attribute.help"
            :instruction="attribute.instruction"
        ></Header>

        <b-field
            type="is-danger"
            :label="attribute.label"
            :message="
                result && result.length >= max
                    ? `${$t('error.validation.files_max_length')} ${max}`
                    : ''
            "
        >
            <b-field type="is-info" :message="fileExtension">
                <b-upload
                    v-model="dropFile"
                    drag-drop
                    expanded
                    @input="showFiles"
                >
                    <section class="section">
                        <div class="content has-text-centered">
                            <p>
                                <b-icon icon="upload" size="is-large"></b-icon>
                            </p>
                            <p>{{ $t('post.file_upload.drop') }}</p>
                        </div>
                    </section>
                </b-upload>
            </b-field>
        </b-field>

        <div
            class="tile is-ancestor"
            v-if="this.attribute.config.type === 'pictures'"
        >
            <div
                class="tile is-vertical is-one-third card"
                v-for="(file, index) in result"
                :key="index"
            >
                <div class="card-image">
                    <figure class="image is-4by3">
                        <img alt="Placeholder image" :src="file.encodedFile" />
                    </figure>
                </div>
                <footer class="card-footer">
                    <a class="card-footer-item" href="#">{{ file.name }}</a>
                    <a
                        class="card-footer-item"
                        href="#"
                        @click.prevent="deleteDropFile(index)"
                        >{{ $t('post.file_upload.delete') }}</a
                    >
                </footer>
            </div>
        </div>

        <div v-else>
            <div class="tags">
                <span
                    class="tag is-primary"
                    v-for="(file, index) in result"
                    :key="index"
                >
                    {{ file.name }}
                    <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                    ></button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Mixin from './Mixin.vue'
import Header from './Header'
import Compressor from 'compressorjs'

export default {
    name: 'FileUpload',
    components: { Header },
    mixins: [Mixin],
    watch: {
        result: function (newValue) {}
    },
    data() {
        return {
            dropFile: [],
            isPicturesUpload: this.attribute.config.type === 'pictures',
            min: this.attribute.config.min,
            max: this.attribute.config.max,
            maxWidth: 1200,
            maxHeight: 1200
        }
    },
    created() {
        this.result = []
    },
    computed: {
        fileExtension: function () {
            return this.attribute.config['accepted-ext']
                ? `${this.$t('post.file_upload.file_extenstion')}` +
                      this.attribute.config['accepted-ext'].join(', ')
                : ''
        }
    },
    methods: {
        deleteDropFile(index) {
            this.result.splice(index, 1)
        },
        showFiles(file) {
            if (this.result.length >= this.max) {
                return
            }

            var fileExt = file.name.split('.').pop()
            var acceptedExt = this.attribute.config['accepted-ext']

            if (acceptedExt && !acceptedExt.includes(fileExt)) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('error.validation.file_upload'),
                    type: 'is-danger'
                })

                return
            }

            if (this.isPicturesUpload === true) {
                new Compressor(file, {
                    quality: 0.8,
                    maxWidth: this.maxWidth,
                    maxHeight: this.maxHeight,
                    success: (compressedImage) => {
                        this.encodeFileToBase64(compressedImage)
                    },
                    error(e) {
                        console.log(e.message)
                    }
                })
            } else {
                this.encodeFileToBase64(file)
            }
        },
        encodeFileToBase64(file) {
            var fr = new FileReader()
            fr.onload = (e) => {
                this.result.push({
                    name: file.name,
                    ext: file.name.split('.').pop(),
                    encodedFile: e.target.result
                })
            }
            fr.readAsDataURL(file)
        }
    }
}
</script>

<style scoped></style>
