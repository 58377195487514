<script>
export default {
    name: 'Mixin',
    props: {
        attribute: {
            required: true,
            type: Object,
            description: 'An attribute that need to be validated'
        }
    },
    watch: {
        result: function () {
            if (this.wasValidated) {
                if (this.isValid()) {
                    this.message = ''
                } else {
                    this.message = this.$t('error.validation.required')
                }
            }
        },
        wasValidated: function (isValidated) {
            if (isValidated) {
                if (!this.isValid()) {
                    this.message = this.$t('error.validation.required')
                }
            }
        }
    },
    data() {
        return {
            result: null,
            wasValidated: false
        }
    },
    methods: {
        isValid() {
            if (this.attribute.required !== 1) return true

            return !!this.result
        }
    }
}
</script>
