<template>
    <main class="page-content" role="main">
        <div class="container-fluid">
            <div class="form-card">
                <div class="row justify-center">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1">
                            <template v-slot:header>
                                <h2>
                                    <span class="icon-stack fs-xxl mr-2">
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-building icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    {{ $t('companies.create') }}
                                </h2>
                            </template>

                            <div class="panel-container collapse show" style="">
                                <div class="panel-content">
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="form-name"
                                                    >{{
                                                        $t(
                                                            'companies.company_name'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="form-name"
                                                    v-model="company.name"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="form-name"
                                                    >{{
                                                        $t('companies.slug')
                                                    }}</label
                                                >
                                                <input
                                                    id="form-name"
                                                    v-model="company.slug"
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <FileUpload
                                                :attribute="imageAttribute"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div v-if="!saved" class="d-flex justify-center">
                            <button
                                :disabled="canCreate()"
                                class="btn btn-primary"
                                href="#"
                                @click.prevent="doCreate"
                            >
                                {{ $t('companies.create_company') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Panel from '@/components/UI/Panel'
import FileUpload from '@/components/Model/Admin/FileUpload'

export default {
    name: 'CompanyBuilder',
    components: {
        Panel,
        FileUpload
    },
    data() {
        return {
            loading: false,
            saved: false,
            imageAttribute: {
                config: {
                    'accepted-ext': ['png', 'jpg', 'jpeg','PNG', 'JPG', 'JPEG'],
                    max: 1,
                    min: 0,
                    type: 'pictures'
                },
                label: 'Logo',
                description: '',
                instruction: ''
            }
        }
    },
    computed: {
        ...mapState('admin', {
            company: (state) => state.newCompany
        })
    },
    methods: {
        ...mapActions('admin', {
            createCompany: 'createCompany',
            updateCompany: 'updateCompany'
        }),
        doCreate: async function () {
            this.loading = true

            let companyId = this.$route.params.companyId

            let request

            if (companyId) {
                company.id = companyId
                request = this.updateCompany()
            } else {
                request = this.createCompany()
            }

            request
                .catch((e) => {
                    this.$store.dispatch('dispatchError', e)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showSuccessMessage: function () {
            this.$store.dispatch('toast', {
                type: 'success',
                title: this.$t('success.title'),
                message: this.$t('success.message')
            })
        },
        showErrorMessage: function (errorMessage) {
            this.$store.dispatch('toast', {
                type: 'error',
                title: this.$t('error.title'),
                message:
                    errorMessage && errorMessage.length > 0
                        ? errorMessage
                        : this.$t('error.validation.retry')
            })
        },
        canCreate: function () {
            return !!this.loading
        }
    }
}
</script>
